<template>
  <div class="content">
	  <el-tree
	    :data="data"
	    :props="defaultProps"
      @node-click="handleNodeClick"
	    accordion
	    >
	  </el-tree>
    <el-divider></el-divider>
    <el-row class="bgW">
      <el-col :span="21">
      <el-form class="search" :inline="true" size="mini" :model="search">
          <el-form-item label="文件名称">
              <el-input v-model="search.fileName" class="w120" clearable placeholder="请输入文件名" />
            </el-form-item>
      </el-form>
       </el-col>
        <el-col :span="2" style="text-align: right;">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getFileData()" :disabled="disabled">搜索</el-button>
        </el-col>
    </el-row>
    <el-button size="small" type="success" @click="uploadDialog" :disabled="disabled">上传新文件</el-button>

   <div class="img-con">
 <!--     <div class="img-item"></div>
      <div class="img-item"></div>
      <div class="img-item"></div> -->
     <el-row >
        <el-col :span="4" v-for="item in fileData">
          <el-card
          class="card"
            :body-style="{ padding: '0px' }">
            <el-image v-if="item.type == 1"
            fit=" cover "
            :src="item.url"
            :preview-src-list="item.srcList"
            class="image"></el-image>
           <div  class="video" v-if="item.type == 2">
              <video :src="item.url" controls="controls"></video>
            </div>
            <div v-if="item.type == 0">
              <el-image
              fit=" cover "
              src="http://xinyiinfors.oss-cn-zhangjiakou.aliyuncs.com/material/1689748563198_1689748563.png"
              :preview-src-list="item.srcList"
              class="image">
              </el-image>
            </div>
            <div style="padding: 14px;">
              <span>{{ item.name }}</span>
              <!-- <div class="bottom clearfix"> -->
                <time class="bottom clearfix time">{{ item.created_at }}</time>
                <span class="bottom clearfix">{{ item.size }}</span>
                <el-button type="text" class="button">
                  <a v-if="item.type != 2" :href="item.url">下载</a>
                  <!-- <span  @click="downFileData(item.id)">下载</span> -->
                </el-button>
                <el-divider direction="vertical" class="button"></el-divider>
                <!-- <el-download :disabled="true" :href="item.url">下载</el-download> -->

                <el-button type="text" class="button" @click="deleteFileData(item.id)">删除</el-button>
              <!-- </div> -->
            </div>
          </el-card>
        </el-col>
      </el-row>
      <pagination
        class="page tc mt10"
        :total="page.total"
        :page.sync="page.page"
        :limit.sync="page.limit"
        :page-sizes="[18]"
        @pagination="getFileData"
      />
    </div>
    <el-dialog title="上传图片" :visible.sync="dialogVisible" width="80%">
      <!-- <span>{{jindu}}</span> -->
      <el-progress :percentage="percentage" :format="format" ></el-progress>
      <el-upload
        class="upload-demo"
        action=""
        :on-remove="handleRemove"
        :http-request="uploadFile"
        :file-list="fileList"
        list-type="text"
        multiple
        >
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-button style="margin-left: 10px;" size="small" type="success" @click="saveFile" :disabled="disabled2">上传</el-button>
        <div slot="tip" class="el-upload__tip"></div>
      </el-upload>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "@/components/Pagination";
import { materialUpload,saveFileLog,getTree,getFile,uploadComplete,deleteFile,downFile } from "@/api/shop";
// import { deleteFile } from "../../api/shop";
class Page {
  total = 0;
  page = 1;
  limit = 18;
}
class Search {
  fileName = ""; // 门店名称
}
export default {
  components: {
    pagination
  },
   data() {
        return {
          search: new Search(),
          fileList: [],
          page: new Page(), // 分页
          formData:new FormData(),
          saveData: [],
          data: [],
          fileData:[],
          defaultProps: {
            children: 'children',
            label: 'label',
          },
          dialogVisible: false,
          leafId:0,
          disabled:true,
          disabled2:true,
          currentDate:"",
          percentage:0,
          now:0,
          all:0,
        };
  },

  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      getTree().then(res => {
        this.data = res;
      });
    },
    // 点击详情
    handleNodeClick(data,node,obj){
      if(data.is_leaf == 1)
      {
        this.leafId = data.id;
        this.disabled = false;
        this.getFileData();
      }else{
        // this.leafId = 0
        // this.disabled = true;
      }
    },
    uploadDialog(){
      this.dialogVisible=true;
    },
    handleRemove(file) {
      let saveData =this.saveData;
      let i=0;
      for(i =0;i<saveData.length;i++)
      {
        if(file.uid==saveData[i]['uid'])
        {
          break;
        }
      }
      if(i == saveData.length)
      {
        this.$message({
          type: "info",
          message: "未找到对应文件"
        });
      }
      saveData.splice(i,1);
      this.saveData = saveData;
    },
    uploadFile(file,filelist){
      this.all=this.all+1;
      // console.log(this.all);
      const saveData = this.saveData;

      this.disabled2 = true;
      const disabled2 = this.disabled2;

      const size = file.file.size;

      const chunkSize = 1024 * 1024 * 10; // 将文件分割成10M大小的分片

      const chunkCount = Math.ceil(size / chunkSize); // 计算分片数量

      let uid =Date.now()+this.all;

      let currentChunk = 0;

      this.uploadResolve(file,currentChunk,chunkCount,uid,size,chunkSize,saveData)
    },
    uploadResolve(file,currentChunk,chunkCount,uid,size,chunkSize,saveData){
      const start = currentChunk * chunkSize;

      const end = (currentChunk + 1) * chunkSize;

      const blobChunk = file.file.slice(start, end); // 获取当前分片的Blob对象

      const formData = new FormData();
      // let percentage=Math.floor(currentChunk/chunkCount)*100;
      let percentage = (100*currentChunk)/chunkCount;
      percentage=percentage.toFixed(2);
      if(percentage==100)
      {
        percentage = 99;
      }
      this.percentage = parseInt(percentage);

      formData.append('chunk', currentChunk);

      formData.append('chunks', chunkCount);

      formData.append('file', blobChunk);

      formData.append("uid", uid);
      formData.append("filename", file.file.name);
        if(currentChunk<chunkCount)
        {
            materialUpload(formData).then(res => {
              if(res.code==200)
              {
                // console.log(currentChunk);
                this.uploadResolve(file,currentChunk+1,chunkCount,uid,size,chunkSize,saveData);
              }else{

              }
            });
        }else{
          uploadComplete(formData).then(res => {
            if(res.code == 200)
            {
              let data = new Array();
              data.file_path=res.data.file_path;
              data.create_time=res.data.create_time;
              data.size=size;
              data.name=res.data.name;
              data.type=res.data.type;
              data.uid=parseInt(res.data.uid);
              saveData.push(data);
              this.saveData=saveData;
              this.percentage=100;
              this.now=this.now+1;
              // console.log(this.all);
              // console.log(this.now);
              if(this.now == this.all)
              {
                this.disabled2=false;
              }else{
                this.disabled2=true;
              }
            }else{
              this.$message({
                type: "error",
                message: "文件上行失败"
              });
            }
          });
        }
    },
    // uploadFile(file){
    //   var formData = new FormData();
    //   formData.append("file", file.file);
    //   formData.append("uid", file.file.uid);
    //   let saveData = this.saveData;
    //   this.disabled2 = true;
      // materialUpload(formData).then(res => {
      //   if(res.code == 200)
      //   {
      //     let data = new Array();
      //     data.file_path=res.data.file_path;
      //     data.create_time=res.data.create_time;
      //     data.size=res.data.size;
      //     data.name=res.data.name;
      //     data.type=res.data.type;
      //     data.uid=parseInt(res.data.uid);
      //     saveData.push(data);
      //   }else{
      //     this.$message({
      //       type: "error",
      //       message: "文件上行失败"
      //     });
      //   }
      //   this.disabled2 = false;
      // });
    //   this.saveData=saveData;
    // },
    saveFile(){
      if(this.leafId == 0)
      {
            this.$message({
              type: "warning",
              message: "请选择正确的目录"
            });
      }else{
        let saveData = {};
        for(let i = 0;i<this.saveData.length;i++)
        {
          saveData[i]={};
          saveData[i]['file_path']=this.saveData[i]['file_path'];
          saveData[i]['name']=this.saveData[i]['name'];
          saveData[i]['size']=this.saveData[i]['size'];
          saveData[i]['type']=this.saveData[i]['type'];
        }
        let tree_id = this.leafId;
        saveFileLog({saveData,tree_id}).then(res=>{
          if(res.code == 200)
          {
            this.$message({
              type: "success",
              message: "保存成功"
            });
          }else{
            this.$message({
              type: "error",
              message: "保存失败"
            });
          }
          this.saveData=[];
          // this.$refs.upload_ref.clearFiles();
          this.fileList=[];
          this.dialogVisible = false;
          this.getFileData();
        });
      }
    },
    getFileData(){
      getFile({tree_id:this.leafId,search:this.search,page:this.page.page,limit:this.page.limit}).then(res=>{
          if(res.code == 200)
          {
            this.fileData = res.data;
            this.page.total = res.total;
          }
      });
    },
    deleteFileData(id){
      deleteFile({id:id}).then(res=>{
        if(res.code == 200)
        {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.getFileData();
        }else{
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
    downFileData(id){
      // window.location.href=url;
      // window.open(url);
      downFile({id:id}).then(res=>{
        if(res.code == 200)
        {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.getFileData();
        }else{
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    },
      format() {
        return this.percentage == 100 ? '100%' : `${this.percentage}%`;
      }
  }
};
</script>

<style lang="scss" scoped>
    .time {
      font-size: 13px;
      color: #999;
    }

    .bottom {
      margin-top: 13px;
      line-height: 12px;
    }

    .button {
      padding: 0;
      float: right;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
        padding-top: 5px;
    }

    .clearfix:after {
        clear: both;
        padding-top: 5px;
    }
    .card{
      padding-top: 20px;
      margin: 10px;
      height: 330px;
    }
    .card .image{
      width: 70%;
      padding: 50px 0px;
      margin: auto;
      display: block;
      height: 100px;
      // border:solid 1px red;
    }
    .card .video{
      width: 90%;
      height: 180px;
      // padding:0px 60px;
      margin: auto;
      // padding-bottom: 56.25%;
      // position: relative;
    }
    .card .video video{
      width: 100%;
      height: 100%;
      // position: absolute;
      // top: 0;
      // left: 0;
      // object-fit: cover;
    }
</style>
